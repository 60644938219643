import { render, staticRenderFns } from "./MoveAppointmentDialog.vue?vue&type=template&id=37e9be58&"
import script from "./MoveAppointmentDialog.vue?vue&type=script&lang=ts&"
export * from "./MoveAppointmentDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_css-loader@6.8.1_vue-template-compiler@2.7.16_webpack@5.94.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports